import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Context } from '../helpers/Context';
import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';

const ProductSearch = () => {
    const { products, getProducts } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState(JSON.parse(localStorage.getItem('productSearch')) || "");
    const [currentPage, setCurrentPage] = useState(1);
    const inputRef = useRef(null);
    const productsPerPage = 12;

    const SplitKeyword = keyword.toLowerCase().split(" ");
    const regPattern = SplitKeyword.map(word => { if (word) return `(?=.*${word})`; else return ""; }).join('');
    const regex = new RegExp(regPattern);

    const filteredProducts = products.filter(item => item.stock).filter(item => {
        if (!keyword) {
            return true;
        } else {
            return regex.test(item.title.toLowerCase()) || regex.test(item.tags.toLowerCase());
        }
    });

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const visiblePageCount = Math.min(7, totalPages);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getStartPage = () => {
        if (currentPage <= Math.ceil(visiblePageCount / 2)) {
            return 1;
        } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
            return totalPages - visiblePageCount + 1;
        } else {
            return currentPage - Math.floor(visiblePageCount / 2);
        }
    };

    const startPage = getStartPage();

    useEffect(() => {
        document.title = "Product Search :: TriggerCraft";
        inputRef.current.focus();
        getProducts();
    }, []);

    return (
        <>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Search</small>
            </nav>
            <div className="row">
                <div className="input-group mb-3">
                    <input ref={inputRef} type="text" className="form-control" onChange={(e) => { setKeyword(e.target.value); localStorage.setItem('productSearch', JSON.stringify(e.target.value)); } } placeholder="Enter Keyword..." aria-describedby="basic-addon" />
                </div>
                {filteredProducts.length === 0 ? (
                    <p>No results found for <strong>"{keyword}"</strong></p>
                ) : (
                    currentProducts.map((product) => (
                        <div className="d-flex col-md-6 col-lg-3 justify-content-evenly mb-3" key={product.id}>
                            <ProductCard
                                product={product}
                                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                                currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                                prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                            />
                        </div>
                    )))}
                {filteredProducts.length > productsPerPage && (<div className="pagination-container pagination">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="pagination-newer">
                        Previous
                    </button>
                    {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
                        const pageNumber = startPage + index;
                        return (
                            pageNumber > 0 &&
                            pageNumber <= totalPages && (
                                <button
                                    key={pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                    disabled={currentPage === pageNumber}
                                    className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button"}
                                >
                                    {pageNumber}
                                </button>
                            )
                        );
                    })}

                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="pagination-older">
                        Next
                    </button>
                </div>)}
            </div>
            <FixedMessenger />
        </>
    );
};

export default ProductSearch;