/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */

import classnames from 'classnames';
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { getAuthToken, getUserDetails } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import FixedMessenger from "./FixedMessenger";
import { ProductCard } from "./ProductCard";
import { Helmet } from "react-helmet";
//import moment from "moment";
import { getProductPrice } from "../helpers/Global";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const ProductView = () => {
  const [hover, setHover] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [newReview, setNewReview] = useState({ name: getAuthToken() ? `${getUserDetails().firstName} ${getUserDetails().lastName}` : "", rating: null, comment: '' });
  const location = useLocation();
  const { sku } = useParams();
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(null);
  const [CategoryList, SetCategoryList] = useState(null);
  const [productData, setProductData] = useState();

  const { handleAddToCart, sendCustomEvent, handleRemoveFromWishlist, handleAddToWishlist, isItemInWishlist, getProducts, subcategories, products, AddProductActivity, getRandomObjectsFromArray, getSubcategories, categories, getReviews, reviews, setReviews, getBrands, brands } = useContext(Context);
  //const productData = products.find(item => item.sku.toLowerCase() === sku.toLowerCase());

  const decrement = () => {
    setQuantity((prevState) => {
      if (prevState === 1) return 1;
      return prevState - 1;
    });
  };
  const increment = () => {
    setQuantity((prevState) => prevState + 1);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const changeImage = (newImage) => {
    setCurrentImage(newImage);
  };

  const scrollTo = (sectionId) => {
    scrollToSection(sectionId);
  };

  const addReview = async (e) => {
    var bearer = 'Bearer ' + getAuthToken();
    const data = {
      Id: 0,
      ProductId: productData.id,
      Name: newReview.name,
      Comment: newReview.comment,
      Rating: newReview.rating,
      DateAdded: new Date(),
      AddedBy: getUserDetails().id,
      Status: 1,
    }
    try {
      if (newReview.name && newReview.comment) {
        setLoading(true);
        const response = await fetch('/api/reviews', {
          method: 'POST',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          setReviews([...reviews, newReview]);
          sendCustomEvent("Product Review", "Reviewed A Product", `${productData.title}`)
          setLoading(false);
        } else {

          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setNewReview({ name: `${getUserDetails().firstName} ${getUserDetails().lastName}`, rating: null, comment: '' });
  };


  const loadData = async (sku) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/products/getbysku/' + sku, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProductData(data);
        //setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }


  const fetchData = async () => {
    console.log("In fetch Data");
    try {
      //await console.log(getProducts());
      //await getProducts();
      //let product = products.find(item => item.sku.toLowerCase() === sku.toLowerCase());
      if (!loading)
        document.title = `${productData.title} :: TriggerCraft`;
      //document.querySelector('meta[name="description"]').setAttribute("content", product.shortDescription.replace(/<\/?[^>]+(>|$)/g, ""));
      //const productData = products.find(item => item.sku.toLowerCase() === sku.toLowerCase());
      //await setProductData(product);


      await getSubcategories();
      await getBrands();
      //console.log(product);
      //setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.title = "Product View :: TriggerCraft";
    //fetchData();
    loadData(sku)
    getProducts();
  }, []);

  useEffect(() => {
    if (productData) {
      //console.log("In got Product Data");
      console.log(productData);
      getSubcategories();
      getBrands();
      AddProductActivity(productData.id, 1);
      getReviews(productData.id);
      setCurrentImage("/assets/imgs/Trigger-Craft-Logo.png")

      const relatedProductList = getRandomObjectsFromArray(products, 4, productData)
      setRelatedProducts(relatedProductList);

      if (productData.images) {
        let images = productData.images;
        if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);
        //console.log(images)
        if (Array.isArray(images)) {
          for (let i = 0; i < 1; i++) {
            if (images[i].includes("DynamicData") && images[i].includes("[")) {
              const parsedImage = JSON.parse(images[i]);
              setCurrentImage(getCurrentDomain() + parsedImage[0]);
            } else {
              setCurrentImage(getCurrentDomain() + images[i]);
            }
          };
        } else if (images.includes("https") && images.includes("[")) {
          setCurrentImage(JSON.parse(images.split(", ")[0])[0]);
        } else {
          setCurrentImage(images.split(", ")[0]);
        }
      }

      setLoading(false);
    }
  }, [productData]);


  const productImage = productData && productData.images ? productData.images.includes("DynamicData") ? JSON.parse(productData.images)[0].includes("\\") && JSON.parse(productData.images)[0].includes("[") ? getCurrentDomain() + JSON.parse(JSON.parse(productData.images))[0].replace(/\\/g, "/") : getCurrentDomain() + JSON.parse(productData.images)[0].replace(/\\/g, "/") : productData.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png";

  const productPrice = getProductPrice(productData && productData.dateSPriceStarts, productData && productData.dateSPriceEnds, productData && productData.sPrice, productData && productData.price);
  const prevProductPrice = (productPrice === productData?.price) ? 0 : productData?.price;

  const currentBrand = productData && brands.find(item => item.id === productData.productBrandId);

  let imageUrls = ["/assets/imgs/Trigger-Craft-Logo.png"];

  if (productData && productData.images) {
    let images = productData.images;
    if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);

    if (Array.isArray(images)) {
      imageUrls = images.map((image) => {
        if (image.includes("DynamicData") && image.includes("[")) {
          const parsedImage = JSON.parse(image);
          return parsedImage;
        } else {
          return image;
        }
      });
    } else if (images.includes("https") && images.includes("[")) {
      imageUrls = JSON.parse(images.split(", ")[0]);
    } else {
      imageUrls = images.split(", ");
    }
  }
  
  const RenderCategoryLink = (CategoryIDs) => {
    if (!CategoryIDs || CategoryIDs === "" || JSON.parse(CategoryIDs).length === 0) {
      return <small className="text-muted">No Categories</small>;
    }

    CategoryIDs = JSON.parse(CategoryIDs);
    return <><span className="mx-1">|</span>{CategoryIDs?.map(GetCategorySlug)}</>;
  }

  const GetCategorySlug = (id, idx) => {
    const cat = subcategories.find(c => c.id == id);
    return cat?.title && cat?.slug && <span key={idx}><Link key={idx} to={"/products/category/" + (cat?.slug)}>{cat?.title}</Link><span className="mx-1">|</span></span>
  }

  const RenderTagLink = (TagLink) => {

    let tagArr = [];
    if (TagLink !== "")
      tagArr = TagLink.split(",");
    return (
      tagArr.length > 0 ?
        <><span className="mx-1">|</span>
          {
            tagArr.map((item, idx) =>
              <span key={idx}>
                <Link style={{ display: "inline-block" }} key={idx} to={"/products/tags/" + item.trim().replace(/\s/g, "_").toLowerCase()}>{item.trim()}</Link><span className="mx-1">|</span>
                {(idx === 4 && tagArr.length > 5) && <><br /> <span className="mx-1">|</span></>}
              </span>
            )
          }
        </>
        :
        <small className="text-muted">No tags</small>
    )
  }

  if (loading)
    return;

  return (
    <>
      {!loading ? (

        <div>
          <Helmet>

            <title>{productData.title} :: TriggerCraft</title>
            <meta name="description" content={productData.shortDescription.replace(/<\/?[^>]+(>|$)/g, "")} />
            <meta name="keywords" content={productData.tags} />
            <meta name="author" content="TriggerCraft" />
            <meta property="og:title" content={productData.title} />
            <meta property="og:description" content={productData.shortDescription.replace(/<\/?[^>]+(>|$)/g, "")} />
            <meta property="og:image" content={currentImage.includes("http") ? currentImage : "https://triggercraft.co.za" + currentImage} />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:title" content={productData.title} />
            <meta name="twitter:description" content={productData.shortDescription.replace(/<\/?[^>]+(>|$)/g, "")} />
            <meta name="twitter:image" content={currentImage.includes("http") ? currentImage : "https://triggercraft.co.za" + currentImage} />
            <meta name="twitter:card" content="summary_large_image" />

            <script type="application/ld+json">{`
              fbq('track', 'ViewContent', { currency: "ZAR", value: ${productPrice}, content_name: "${productData.title}", content_ids: ['${productData.sku}'] });
             `}</script>
          </Helmet>

          <nav className="mb-3 crumbs">
            <Link to="/" className="breadcrumb-active"><small>Home</small>
            </Link>
            <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
            <Link to="/products" className={location.pathname.startsWith("/products") ? "breadcrumb-active" : "breadcrumb-not-active"}><small>Products</small>
            </Link>
            <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
            <small className={location.pathname === "/products/product-view" ? "breadcrumb-active" : "breadcrumb-not-active"}>{productData?.title}</small>
          </nav>
          <div className="single-product-main-content">
            <div className="layout">
              <div className="single-product-page">
                <div className="left">
                  <img src={currentImage} />
                  {/*<hr className="m-0"/>*/}
                  <div className="option d-flex">
                    {imageUrls.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl}
                        alt={`Image ${index + 1}`}
                        className="image-item"
                        onClick={() => changeImage(imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl)}
                      />))}
                  </div>
                </div>
                <div className="right">
                  <span className="name">{productData.title}</span>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    <img style={{ width: "100px", cursor: "pointer" }} onClick={e => currentBrand.id ? navigate(`/brands/products/${currentBrand.slug}`) : null} src={currentBrand?.brandImages ? getCurrentDomain() + currentBrand.brandImages : "/assets/imgs/Trigger-Craft-Logo.png"} />
                    <small className="text-muted">availability: {productData.stock > 0 ? <span style={{ color: "#74bc1f" }}>In Stock</span> : <span className="text-danger">Out Of Stock</span>}</small>
                  </div>
                  {reviews.length ? <span className="mb-3">< i className="fa-solid fa-star" style={{ color: "#ffc107", fontSize: "16px" }}></i>&nbsp; <strong>{productData.avgRating}</strong>&nbsp; <strong style={{ cursor: "pointer", color: "#74bc1f" }} onClick={() => { handleTabClick('3'); scrollTo('reviewSection'); }}>{productData.reviews} {productData.reviews > 1 ? "REVIEWS" : "REVIEW"}</strong></span>
                    : <span className="mb-3"><small>Be the first to</small> <strong style={{ cursor: "pointer", color: "#74bc1f" }} onClick={() => { handleTabClick('3'); scrollTo('reviewSection'); }}>REVIEW</strong></span>}
                  <span className="desc"><div dangerouslySetInnerHTML={{ __html: productData.shortDescription.replace(/\\n/g, '') }} /></span>

                  {prevProductPrice > 0 ? <del className="text-muted">R {prevProductPrice}.00</del> : ""}
                  <span className="price">R {productPrice}.00</span>

                  <div className="cart-buttons">
                    <div className="quantity-buttons">
                      <span onClick={decrement}>-</span>
                      <span>{quantity}</span>
                      <span onClick={increment}>+</span>
                    </div>
                    {productData.stock > 0 ?
                      <button className="add-to-cart-button" onClick={() => { handleAddToCart({ product: productData, productId: productData.id, price: productPrice, img: productImage }, quantity); setQuantity(1); }} >
                        <i className="fas fa-shopping-cart ms-2"></i> ADD TO CART
                      </button> :
                      <button className="add-to-cart-button">OUT OF STOCK</button>}

                    {isItemInWishlist(productData.id) ? <i onClick={() => handleRemoveFromWishlist(productData.id)} className="fa-solid fa-heart text-danger ms-3 fs-2" title="Remove From Wishlist"></i> : <i onClick={() => handleAddToWishlist({ product: productData, productId: productData.id, price: productPrice, img: productImage })} className="fa-regular fa-heart fw-light ms-3 fs-2" title="Add To Wishlist"></i>}
                  </div>

                  <span className="divider" />
                  <div className="info-item">
                    <span className="text-bold">
                      <b>Category: </b> <br />{productData.selectedCategories && RenderCategoryLink(productData.selectedCategories)}
                    </span>
                    <span className="text-bold">
                      <b>Tags</b><br />{RenderTagLink(productData.tags)}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div id="reviewSection">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => handleTabClick('1')} >
                      Description
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => handleTabClick('2')} >
                      Specification
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => handleTabClick('3')} >
                      Reviews
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="card p-4">
                    <Row>

                      {productData.about && <><hr />
                        <Col sm="12">
                          <span className="desc"><div dangerouslySetInnerHTML={{ __html: productData.about.replace(/\\n/g, '') }} /></span>
                        </Col></>}

                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="card p-4">
                    <Row>
                      <Col sm="12">
                        <Table responsive>
                          <tbody>
                            <tr>
                              <th scope="row" className="w-25">SKU</th>
                              <td><strong>{productData.sku}</strong></td>
                            </tr>
                            <tr>
                              <th scope="row" className="w-25">GTIN</th>
                              <td><strong>{productData.gtin}</strong></td>
                            </tr>
                            <tr>
                              <th scope="row" className="w-25">Width</th>
                              <td><strong>{productData.width}</strong></td>
                            </tr>
                            <tr>
                              <th scope="row">Height</th>
                              <td><strong>{productData.height}</strong></td>
                            </tr>
                            <tr>
                              <th scope="row">Weight</th>
                              <td><strong>{productData.weight}</strong></td>
                            </tr>
                            <tr>
                              <th scope="row">Length</th>
                              <td><strong>{productData.length}</strong></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      {productData.additionalInfo && <><hr />
                        <Col sm="12">
                          <span className="desc"><div dangerouslySetInnerHTML={{ __html: productData.additionalInfo }} /></span>
                        </Col></>}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" className="card p-4">
                    <Row>
                      <Col sm="6">
                        <Card body>
                          <CardTitle>Review Product</CardTitle>
                          {!reviews.find(item => getAuthToken() && item.addedBy === getUserDetails().id) ? <><div className="rating">
                            {[...Array(5)].map((star, index) => {
                              const currentRating = index + 1;
                              return (<label key={index}>
                                <input type="radio" name="rating" value={currentRating} onClick={() => setNewReview({ ...newReview, rating: currentRating })} />
                                <i className="fa-solid fa-star fs-2 star" style={{ color: currentRating <= (hover || newReview.rating) ? "#ffc107" : "#e4e5e9" }} onMouseEnter={() => setHover(currentRating)} onMouseLeave={() => setHover(null)} ></i>
                              </label>);
                            })}
                          </div>
                            <div className="mb-3">
                              <label>Comment:</label>
                              <br />
                              <textarea
                                value={newReview.comment}
                                className="w-100"
                                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                              />
                            </div>
                            <button className="hero-button w-100" onClick={() => { getAuthToken() ? addReview() : alert("Please log in to review product") }}>Rate</button></> : <CardText>You already reviewed this product.</CardText>}
                        </Card>
                      </Col>
                      <Col sm="6">
                        <Card body>
                          <CardTitle>Customer Reviews</CardTitle>
                          <div className="reviews-container">
                            {reviews.length ? (reviews.map((review, index) => {
                              const stars = Array.from({ length: review.rating }, (_, i) => i + 1);

                              return (
                                <div key={index} className="customer-review">
                                  <div className="rating">
                                    {stars.map((starIndex) => (
                                      <span key={starIndex} className="star">&#9733;</span>
                                    ))}
                                  </div>
                                  <div className="customer-info">
                                    <p className="customer-name">{review.name}</p>
                                    <p className="review-date">{new Date(review.dateAdded).toLocaleDateString()}</p>
                                  </div>
                                  <p className="review-comment">{review.comment}</p>
                                </div>
                              );
                            })
                            ) : <CardText>No reviews at the moment for this product, be the first to pull the trigger.</CardText>}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
              <hr />
              <h2>Related Products</h2>
              <div className="row px-3 mb-3">
                {relatedProducts.map((product) => {
                  return (
                    <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                      <ProductCard
                        product={product}
                        imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                        currPrice={getProductPrice(product?.dateSPriceStarts, product?.dateSPriceEnds, product?.sPrice, product?.price)}
                        prevPrice={(getProductPrice(product?.dateSPriceStarts, product?.dateSPriceEnds, product?.sPrice, product?.price) === product?.price) ? 0 : product?.price}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>) : <div>Loading....</div>}
      <FixedMessenger />
    </>
  );
};

export default ProductView;