import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getAuthToken } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import CartItem from "./CartItem";

const CheckoutPage = () => {
    const [couponCode, setCouponCode] = useState('');
    const navigate = useNavigate();
    const { cartItems, cartSubTotal, cartTotal, applyCoupon, couponError, appliedCoupon, cart } = useContext(Context);

    //const handlePayment = async () => {
    //    var bearer = 'Bearer ' + getAuthToken();
    //    var data = {
    //        Id: cart.id,
    //        CartTotal: cartSubTotal,
    //        FirstName: "",
    //        LastName: "",
    //        Email: "",
    //        ReturnUrl: getCurrentDomain() + "/return-payment",
    //        CancelUrl: getCurrentDomain() + "/cancel-payment",
    //        NotifyUrl: getCurrentDomain() + "/notify-payment",
    //    }
    //    try {
    //        const response = await fetch('/api/payfast/processpayment', {
    //            method: 'POST',
    //            headers: {
    //                'Authorization': bearer,
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(data),
    //        });

    //        if (response.ok) {
    //            console.log("done")
    //        } else {
    //            console.log("oh no")
    //        }
    //    } catch (err) {
    //        console.log(err);
    //    }
    //};

    const calculateTotal = (cartSubTotal, deliveryFee, coupon) => {
        let total = cartSubTotal;

        if (coupon) {
            if (coupon.type === 1) {
                total -= coupon.amount;
            } else if (coupon.type === 2) {
                total -= (total * coupon.amount) / 100;
            }
        }

        return total + deliveryFee;
    };

    return (
        <div className="">
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Checkout</small>
            </nav>
            <div className="" ></div>
            <div className="cart-content">
                {/*<div className="cart-header">*/}
                {/*    <span className="heading">Shopping Cart</span>*/}
                {/*    <span className="close-btn" onClick={() => setShowCart(false)} >*/}
                {/*        <i className="fa-solid fa-xmark"></i>*/}
                {/*        <span className="text">close</span>*/}
                {/*    </span>*/}
                {/*</div>*/}

                {!!cartItems.length && (
                    <><h1 className="mb-4">Checkout</h1>
                        <div className="scrollable-cart">
                            <CartItem />
                        </div>
                        <hr />
                        <div className="">
                            <div className="row d-flex flex-wrap justify-content-between mb-3">
                                {<div className="coupon-button col-sm-12 col-md-4">
                                    <div className="input-group mb-3">
                                        <input
                                            className="coupon-input"
                                            type="text"
                                            placeholder="Enter coupon code"
                                            value={couponCode}
                                            onChange={(e) => setCouponCode(e.target.value)}
                                        />
                                        <button className="coupon-button" onClick={() => getAuthToken() ? applyCoupon(couponCode) : alert("Please login or register to apply code")}>Apply</button>
                                    </div>
                                    {couponError && <p className="coupon-error">{couponError}</p>}
                                    {appliedCoupon && appliedCoupon?.id ? (
                                        <div>
                                            <p className="coupon-message">Coupon applied: {appliedCoupon.code}</p>
                                            {/*<p className="coupon-message">Discount: R {appliedCoupon.amount}</p>*/}
                                        </div>
                                    ): ""}
                                </div>}
                                <div className="subtotal col-sm-12 col-md-6">
                                    <div className="d-flex justify-content-between">
                                        <span className="text">Promo Discount: </span>
                                        <span className="text total">
                                            {appliedCoupon ? (
                                                appliedCoupon.type === 1
                                                    ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(appliedCoupon.amount)
                                                    : appliedCoupon.type === 2
                                                        ? `${appliedCoupon.amount} %`
                                                        : new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(0)
                                            ) : (
                                                new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(0)
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="text">Delivery Fee: </span>
                                        <span className="text total">
                                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(65)}
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <span className="text">Subtotal:</span>
                                        <span className="text total">
                                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(cartSubTotal)}
                                        </span>
                                    </div>
                                    <div className="d-flex fs-4 justify-content-between">
                                        <strong className="text">Total:</strong>
                                        <strong className="text total">
                                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(calculateTotal(cartSubTotal, 65, appliedCoupon))}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex text-end">
                                <button className="checkout-btn" onClick={() => getAuthToken() ? navigate("/order-completion") : alert("Please login or register to complete order")} >Checkout &nbsp;<i className="fas fa-chevron-right ms-2"></i></button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CheckoutPage;