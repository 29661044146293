import { GoogleOAuthProvider } from '@react-oauth/google';
import { AboutUs } from "./components/AboutUs";
import { AdminDashboard } from "./components/admin/AdminDashboard";
import { AdminLanding } from "./components/admin/AdminLanding";
import { AdminOrders } from "./components/admin/AdminOrders";
import { AuditLog } from "./components/admin/AuditLog";
import { BannerLocations } from "./components/admin/BannerLocations";
import { Banners } from "./components/admin/Banners";
import { BannerSections } from "./components/admin/BannerSections";
import { ContactMessages } from "./components/admin/ContactMessages";
import { ContactReasons } from "./components/admin/ContactReasons";
import { FAQs as AdminFAQs } from "./components/admin/FAQs";
import { OrderDashboard } from "./components/admin/OrderDashboard";
import { PageContent } from "./components/admin/PageContent";
import { ProductBrands } from "./components/admin/ProductBrands";
import { ProductCategories } from "./components/admin/ProductCategories";
import { ProductRange } from "./components/admin/ProductRange";
import { Products } from "./components/admin/Products";
import { ProductSubCategories } from "./components/admin/ProductSubCategories";
import { ProductType } from "./components/admin/ProductType";
import { PromoCodes } from "./components/admin/PromoCodes";
import { Promotions } from "./components/admin/Promotions";
import { UserData } from "./components/admin/UserData";
import BrandPage from "./components/BrandPage";
import Categories from "./components/Categories";
import CategoryPage from "./components/CategoryPage";
import CheckoutPage from "./components/CheckoutPage";
import ContactUs from "./components/ContactUs";
import CustomerDeliveryAddresses from "./components/CustomerDeliveryAddresses";
import DeliveryDetails from "./components/DeliveryDetails";
import { FAQs } from "./components/FAQs";
import ForgotPassword from "./components/ForgotPassword";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import OrderCompletion from "./components/OrderCompletion";
import OrderHistory from "./components/OrderHistory";
import { PayFastCancelled } from "./components/payfast/Cancelled";
import PayFastComplete from "./components/payfast/Complete";
import { PersonalInformation } from "./components/PersonalInformation";
import ProductBrandPage from "./components/ProductBrandPage";
import ProductPage from "./components/ProductPage";
import ProductSearch from "./components/ProductSearch";
import ProductView from "./components/ProductView";
import PromotionPage from "./components/PromotionPage";
import { Register } from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import TagProducts from "./components/TagProducts";
import { TestPayments } from "./components/TestPayments";
import WishList from "./components/WishList";



const AppRoutes = [
  { index: true, element: <Home /> },
  //{ path: '*', element: <NotFound /> },
  { path: '/personal-infomation', element: <PersonalInformation /> },
  { path: '/products/product-view/:sku', element: <ProductView /> },
  { path: '/wish-list', element: <WishList /> },
  { path: '/products', element: <ProductPage IsTagFilter={false} /> },
  { path: '/brands', element: <BrandPage /> },
  { path: '/categories', element: <Categories /> },
  //{ path: '/categories/razer/:category', element: <RazerCategoryPage /> },
  { path: '/brands/products/:slug', element: <ProductBrandPage /> },
  //{ path: '/brands/:slug', element: getAuthToken() ? <BrandSpecificPage /> : <Navigate to="/login" replace /> },
  { path: '/products/category/:category', element: <CategoryPage /> },
  { path: '/products/tags/:tagName', element: <TagProducts /> },
  { path: '/products/category', element: <CategoryPage /> },
  { path: '/faqs', element: <FAQs /> },
  { path: '/product-search', element: <ProductSearch /> },
  { path: '/order-history', element: <OrderHistory /> },
  { path: '/delivery-details/:id', element: <DeliveryDetails /> },
  { path: '/customer-delivery-addresses', element: <CustomerDeliveryAddresses /> },
  { path: '/promotions', element: <PromotionPage /> },
  { path: '/test-payments', element: <TestPayments /> },
  { path: '/about-us', element: <AboutUs /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/checkout', element: <CheckoutPage /> },
  { path: '/checkout/complete', element: <PayFastComplete /> },
  { path: '/checkout/cancel', element: <PayFastCancelled /> },
  { path: '/order-completion', element: <OrderCompletion /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:id', element: <ResetPassword /> },
];

export const AdminRoutes = [
  { index: true, element: <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ANALYTICS_CLIENT_ID_NEW}><AdminDashboard /></GoogleOAuthProvider> },
  { path: '/order-dashboard', element: <OrderDashboard /> },
  { path: '/administration', element: <AdminLanding /> },
  { path: '/contact-reasons', element: <ContactReasons /> },
  { path: '/users', element: <UserData /> },
  { path: '/banners', element: <Banners /> },
  { path: '/promo-codes', element: <PromoCodes /> },
  { path: '/promotions', element: <Promotions /> },
  { path: '/products', element: <Products /> },
  { path: '/product-types', element: <ProductType /> },
  { path: '/product-ranges', element: <ProductRange /> },
  { path: '/product-categories', element: <ProductCategories /> },
  { path: '/product-sub-categories', element: <ProductSubCategories /> },
  { path: '/product-brands', element: <ProductBrands /> },
  { path: '/page-content', element: <PageContent /> },
  { path: '/contact-messages', element: <ContactMessages /> },
  { path: '/banner-sections', element: <BannerSections /> },
  { path: '/banner-locations', element: <BannerLocations /> },
  { path: '/audit-log', element: <AuditLog /> },
  { path: '/faqs', element: <AdminFAQs /> },
  { path: '/admin-orders', element: <AdminOrders /> },
];

export default AppRoutes;
