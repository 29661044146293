/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable default-case */
import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, Filter, ExcelExport, Edit, Toolbar, InfiniteScroll, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { ImageThumbnails } from '../ImageThumbnails';
import { Context } from '../../helpers/Context';
import Select2 from '../../helpers/Select2';
import '../../css/Select2.css';
import { PreviewCards } from '../PreviewCards';
import { exportProducts, handleExportClick } from '../../helpers/Global';
import * as XLSX from 'xlsx';

export class Products extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Preview', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    
    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, previewModal: false, uploadModal: false, gridCommands: commandTemplate, gridToolbar: [],
      Id: 0,
      Slug: '',
      ProductBrandId: 0,
      fProductBrandId: 0,
      fCategoryId: 0,
      fProductName: '',
      fSubcategoryId: [],
      ProductRangeId: 0,
      ProductTypeId: 0,
      Title: '',
      ShortDescription: '',
      DisplayOrder: 0,
      Weight: '',
      Height: '',
      Length: '',
      Width: '',
      SPrice: 0,
      DateSPriceStarts: new Date(),
      DateSPriceEnds: new Date(),
      Upsells: 0,
      CrossSells: '',
      Stock: 0,
      Quantity: 0,
      Price: 0,
      Images: [],
      Sku: '',
      Published: 0,
      Featured: 0,
      GTIN: '',
      About: '',
      AdditionalInfo: '',
      Tags: '',
      RelatedProducts: '',
      MetaData: '',
      ExternalURL: '',
      DisplayType: 0,
      OutOfStock: false,
      Ongoing: false,
      StockCount: 0,
      LowStockAmount: 0,
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,
      PrevId: 0,
      BrandList: [],
      CategoryList: [],
      SelectedCategories: [],
      SubCategoryList: [],
      SelectedSubCategories: [],
      PreviewProduct: [],
      excelData: [],
    };
    this.toggle = this.toggle.bind(this);
    this.previewToggle = this.previewToggle.bind(this);
    this.uploadToggle = this.uploadToggle.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this);
    this.handleUnSelectChange = this.handleUnSelectChange.bind(this);
    this.handleFilterUnSelectChange = this.handleFilterUnSelectChange.bind(this);
    this.uploadExcelData = this.uploadExcelData.bind(this)
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  previewToggle() {
    this.setState({
      previewModal: !this.state.previewModal,
      PrevId: 0,
    });
  }

  uploadToggle() {
    this.setState({
      uploadModal: !this.state.uploadModal
    });
  }

  componentDidMount() {
    document.title = "Products Administration";
    this.loadData();
  }

  editItem = (id) => {

    //console.log(data, id)
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      console.log(data)
      this.setState({ Id: data.id, Slug: data.slug, ProductBrandId: data.productBrandId, ProductRangeId: data.productRangeId, SelectedCategories: data.selectedCategories && JSON.parse(data.selectedCategories), GTIN: data.gtin, ProductTypeId: data.productTypeId, Title: data.title, ShortDescription: data.shortDescription, DisplayOrder: data.displayOrder, Weight: data.weight, Height: data.height, Length: data.length, Width: data.width, SPrice: data.sPrice, DateSPriceStarts: data.dateSPriceStarts, DateSPriceEnds: data.dateSPriceEnds, Upsells: data.upsells, CrossSells: data.crossSells, Stock: data.stock, Quantity: data.quantity, Price: data.price, Images: data.images, Sku: data.sku, Published: data.published, Featured: data.featured, About: data.about, AdditionalInfo: data.additionalInfo, Tags: data.tags, RelatedProducts: data.relatedProducts, MetaData: data.metaData, ExternalURL: data.externalURL, DisplayType: data.displayType, OutOfStock: data.outOfStock, Ongoing: data.ongoing, StockCount: data.stockCount, LowStockAmount: data.lowStockAmount, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        ProductBrandId: 0,
        ProductRangeId: 0,
        ProductTypeId: 0,
        fProductTypeId: 0,
        fCategoryId: 0,
        fSubcategoryId: 0,
        Title: '',
        ShortDescription: '',
        DisplayOrder: 0,
        Weight: '',
        Height: '',
        Length: '',
        Width: '',
        SPrice: 0,
        DateSPriceStarts: new Date(),
        DateSPriceEnds: new Date(),
        Upsells: 0,
        CrossSells: '',
        Stock: 0,
        Quantity: 0,
        Price: 0,
        Images: '',
        Sku: '',
        Published: 0,
        Featured: 0,
        GTIN: '',
        About: '',
        AdditionalInfo: '',
        SelectedCategories: [],
        Tags: '',
        RelatedProducts: '',
        MetaData: '',
        ExternalURL: '',
        DisplayType: 0,
        OutOfStock: false,
        Ongoing: false,
        StockCount: 0,
        LowStockAmount: 0,
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });

  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.ProductBrandId, 'tbProductBrandIdError', '* required');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    valid &= fieldRequired(this.state.ShortDescription, 'tbShortDescriptionError', '* required');
    valid &= fieldRequired(this.state.Sku, 'tbSkuError', '* required');
    valid &= fieldRequired(this.state.Price, 'tbPriceError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId, null);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  previewItem = (id) => {
    let brandId = 0;
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      brandId = data.productBrandId;
      this.setState({ PreviewProduct: data, Id: data.id, Slug: data.slug, ProductBrandId: data.productBrandId, ProductRangeId: data.productRangeId, SelectedCategories: data.selectedCategories && JSON.parse(data.selectedCategories), ProductTypeId: data.productTypeId, Title: data.title, ShortDescription: data.shortDescription, DisplayOrder: data.displayOrder, Weight: data.weight, Height: data.height, Length: data.length, Width: data.width, SPrice: data.sPrice, DateSPriceStarts: data.dateSPriceStarts, DateSPriceEnds: data.dateSPriceEnds, Upsells: data.upsells, CrossSells: data.crossSells, Stock: data.stock, Quantity: data.quantity, Price: data.price, Images: data.images, Sku: data.sku, Published: data.published, Featured: data.featured, About: data.about, AdditionalInfo: data.additionalInfo, Tags: data.tags, RelatedProducts: data.relatedProducts, MetaData: data.metaData, ExternalURL: data.externalURL, DisplayType: data.displayType, OutOfStock: data.outOfStock, Ongoing: data.ongoing, StockCount: data.stockCount, LowStockAmount: data.lowStockAmount, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, });
    }

    this.setState({ PrevId: brandId, previewModal: true });
  }

  async uploadExcelData() {
    let valid = true;
    valid &= fieldRequired(this.state.excelData.length, "uploadData", "* required")
    if (valid) {
      this.setState({ uploadModal: false })
      let dataToUpload = this.state.excelData
      //console.log(dataToUpload)
      for (let i = 0; i < dataToUpload.length; i++) {
        //console.log(dataToUpload[i]["Brand *"], typeof dataToUpload[i]["Brand *"])
        let brandId = typeof dataToUpload[i]["Brand *"] == "number" ? dataToUpload[i]["Brand *"] : parseInt(dataToUpload[i]["Brand *"].split(" - ")[1]);
        let selectedCategory = dataToUpload[i].Subcategories.includes("[") ? dataToUpload[i].Subcategories : JSON.stringify([parseInt(dataToUpload[i].Subcategories.split(" - ")[1])]);

        var body = {
          Id: this.state.Id,
          Slug: dataToUpload[i]["Slug *"],
          ProductBrandId: brandId,
          SelectedCategories: selectedCategory,
          ProductRangeId: this.state.ProductRangeId,
          ProductTypeId: this.state.ProductTypeId,
          GTIN: dataToUpload[i].GTIN.toString(),
          Title: dataToUpload[i]["Title *"],
          ShortDescription: dataToUpload[i].ShortDescription ? dataToUpload[i]?.ShortDescription : "",
          DisplayOrder: this.state.DisplayOrder,
          Weight: dataToUpload[i]["Weight *"].toString(),
          Height: dataToUpload[i]["Height *"].toString(),
          Length: dataToUpload[i]["Length *"].toString(),
          Width: dataToUpload[i]["Width *"].toString(),
          SPrice: dataToUpload[i]?.SPrice ? dataToUpload[i].SPrice : 0,
          DateSPriceStarts: dataToUpload[i].DateSPriceStarts ? new Date(dataToUpload[i].DateSPriceStarts) : new Date(),
          DateSPriceEnds: dataToUpload[i].DateSPriceEnds ? new Date(dataToUpload[i].DateSPriceEnds) : new Date(),
          Upsells: this.state.Upsells,
          CrossSells: this.state.CrossSells,
          Stock: dataToUpload[i]["Stock *"],
          Quantity: dataToUpload[i].Quantity,
          Price: dataToUpload[i]["Price *"],
          Images: dataToUpload[i]?.Images ? dataToUpload[i].Images : "",
          Sku: dataToUpload[i]["SKU *"] ? dataToUpload[i]["SKU *"] : "",
          Published: this.state.Published,
          Featured: this.state.Featured,
          About: dataToUpload[i].About ? dataToUpload[i]?.About : "",
          AdditionalInfo: dataToUpload[i].AdditionalInfo ? dataToUpload[i]?.AdditionalInfo : "",
          Tags: dataToUpload[i].Tags ? dataToUpload[i].Tags : "",
          RelatedProducts: this.state.RelatedProducts,
          MetaData: this.state.MetaData,
          ExternalURL: this.state.ExternalURL,
          DisplayType: this.state.DisplayType,
          OutOfStock: dataToUpload[i].OutOfStock,
          Ongoing: dataToUpload[i].Ongoing,
          StockCount: dataToUpload[i]["StockCount *"],
          LowStockAmount: dataToUpload[i].LowStockAmount,
          AddedBy: this.state.AddedBy,
          DateAdded: this.state.DateAdded,
          ModifiedBy: this.state.ModifiedBy,
          DateModified: this.state.DateModified,
          Status: this.state.Status,
        }
        //console.log(body)
        this.saveBulkData(body);
      }
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Preview':
        this.previewItem(args.rowData.id);
        break;
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  RenderBrands = (props) => {
    const brand = this.state.BrandList.find(item => item.id === props.productBrandId);
    if (brand) {
      return (
        <React.Fragment>
          {brand.title}
        </React.Fragment>
      );
    }
  }

  RenderCategories = (props) => {
    const selectedCategoryIds = props.selectedCategories;
    //console.log(this.state.SubCategoryList, selectedCategoryIds == '""', selectedCategoryIds)
    const categories = this.state.SubCategoryList.filter(item => selectedCategoryIds && selectedCategoryIds !== '""' && JSON.parse(selectedCategoryIds).find(a => item.id === a));
    const categoryTitles = categories.map(category => category.title).join(', ');
    return (
      <React.Fragment>
        {categoryTitles}
      </React.Fragment>
    );
  }

  renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    const FilterOptions = {
      type: 'Excel'
    };

    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} enableInfiniteScrolling={true} allowSorting={true}  allowFiltering={true} filterSettings={FilterOptions}   >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='150' headerText="Title" />
          <ColumnDirective field='Sku' width='100' headerText="Sku" />
          <ColumnDirective field='' width='50' headerText="Brand" template={this.RenderBrands} />
          <ColumnDirective field='' width='150' headerText="Category" template={this.RenderCategories} />
          <ColumnDirective field='Stock' width='50' headerText="Stock" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar, Filter, InfiniteScroll]} />
      </GridComponent>
    );
  }

  

  handleUpdatedImageUrls = (updatedUrls) => {
    this.setState({ Images: updatedUrls });
  }

  handleSelectChange(event) { const selectedValues = Array.from(event.target.selectedOptions).map(option => parseInt(option.value)); this.setState({ SelectedCategories: [...selectedValues] }); }

  handleUnSelectChange(event) {
    const selectedValues = Array.from(event.target.selectedOptions).map(option => option.value);
    let newData = this.state.SelectedSubCategories.filter(item => item !== selectedValues[0])
    this.setState({ SelectedSubCategories: newData });
  }

  //handleFilterSelectChange(event) { const selectedValue = Array.from(event.target.selectedOptions).map(option => parseInt(option.value)); this.setState({ fSubcategoryId: selectedValue }); }
  handleFilterSelectChange(event) {
    //const selectedValue = Array.from(event.value).map(option => parseInt(option));
    this.setState({ fSubcategoryId: event.value });
    console.log(event.value);
  }

  handleFilterUnSelectChange(event) {
    this.setState({ fSubcategoryId: [] });
  }

  filterProductsByCategories = (selectedCategoryIds, productCategories, products) => {
    // Extract subCategoryIds from all selected categories
    
    //console.clear();
    //console.log(selectedCategoryIds, productCategories, products);
    var subCategoryIds = [];
    for (var i = 0; i < productCategories?.length; i++) {
      if (selectedCategoryIds.indexOf(productCategories[i].id) !== -1) {
        for (var j = 0; j < productCategories[i].subCategoryIds?.length; j++) {
          subCategoryIds.push(productCategories[i].subCategoryIds[j].id);
        }
      }
    }
    //console.log(subCategoryIds);
    
    var filteredProducts = [];
    for (var k = 0; k < products?.length; k++) {
      if (products[k].selectedCategories) {
        var selectedCategories = JSON.parse(products[k].selectedCategories);
        for (var l = 0; l < selectedCategories?.length; l++) {
          if (subCategoryIds.indexOf(selectedCategories[l]) !== -1) {
            filteredProducts.push(products[k]);
            break;
          }
        }
      }
    }
    
    return filteredProducts;
  };

  render() {
    let data = this.state.editData;
    const { handleNavigate } = this.context;
    let CategoryList = [];
    if (Array.isArray(this.state.CategoryList)) {
      CategoryList = this.state.CategoryList.map(item => ({
        label: item.title,
        value: item.id
      }))
    }

    let images = this.state.Images;
    const Fields = { text: 'label', value: 'value' };
    if (this.state.Images.includes("\\\\")) {
      images = JSON.parse(this.state.Images)
    }
    if (this.state.fProductBrandId !== 0) {
      data = data?.filter(item => item.productBrandId === this.state.fProductBrandId || (!this.state.fProductBrandId && !item.productBrandId));
    }
    

    if (this.state.fProductName) {
      data = data?.filter(item => item.title?.toLowerCase().includes(this.state.fProductName) || item.sku?.toLowerCase().includes(this.state.fProductName));
    }
    if (this.state.fSubcategoryId.length) {
      data = this.filterProductsByCategories(this.state.fSubcategoryId, this.state.CategoryList, data);
    }
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    let mapedData = this.state.CategoryList.map((item, index) => { return { text: item.title, children: this.state.SubCategoryList.filter(sub => sub.categoryId === item.id).map(sub => { return { text: sub.title, id: sub.id } }) } })

    const uploadExcel = (e) => {
      const file = e.target.files[0];
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0])

        reader.onload = (e) => {
          const contents = e.target.result;
          const wb = XLSX.read(contents, { type: "binary" });
          const sheetName = wb.SheetNames[0];
          const sheet = wb.Sheets[sheetName];
          const newData = XLSX.utils.sheet_to_json(sheet);
          this.setState({ excelData: newData })
          //console.log(newData)
        }

      } else {
        console.error('Invalid file type');
      }
    }

    
    return (
      <>
        <div className="container">
          <Row className="mb-4">
            <Col xs={4}>
              <h1>Products Administration</h1>
            </Col>
            <Col xs={8} className="text-end align-self-center">
              <Button className="mx-1" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left ms-2"></i> Back To Admin</Button>
              <Button className="mx-1" color="primary" size="sm" data-recordid="0" onClick={e => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className='card card-body shadow mb-3'>
                <Col xs={12} className="text-end align-self-center">
                  <Button className="me-1 mb-2" color="primary" outline size="sm" data-recordid="0" onClick={e => handleExportClick("PRODUCTS_TEMPLATE", this.state.SubCategoryList, this.state.BrandList)}> Download Template</Button>
                  <Button className="me-1 mb-2" color="info" size="sm" data-recordid="0" onClick={e => exportProducts("TriggerCraft_Products", data)}> Download Products</Button>
                  <Button className="me-1 mb-2" color="success" size="sm" data-recordid="0" onClick={e => this.uploadToggle()}> Upload Products</Button>
                </Col>
                <b>Filters</b>
                <div className='row'>
                  {<div className='col-md-4'>
                    <DropDownListComponent id='ddProductBrandId' name='ddProductBrandId' placeholder='Brand' dataSource={[{ title: "All", id: 0 }, ...this.state.BrandList]} fields={{ text: 'title', value: 'id' }} floatLabelType='Always' value={this.state.fProductBrandId} change={e => this.setState({ fProductBrandId: e.itemData ? e.itemData.id : 0 })} />
                  </div>}
                  {<div className='col-md-4'>
                    <TextBoxComponent id='tbProductName' name='tbProductName' placeholder='Product Name / SKU' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.fProductName} onChange={e => this.setState({ fProductName: e.target.value.toLowerCase() })} />
                  </div>}
                  <div className='col-md-4'>
                    <Label className="mb-0 text-dark">Subcategories</Label>
                    {/*<Select2 className='form-control form-control-sm' multiple defaultValue={this.state.fSubcategoryId} data={mapedData} onSelect={this.handleFilterSelectChange} onUnselect={this.handleFilterUnSelectChange} />*/}
                    {/*<MultiSelectComponent id="mtselement" placeholder="Select Subcategories" value={this.state.fSubcategoryId} fields={Fields} onChange={e => { const selectedData = e.value; this.setState({ fSubcategoryId: selectedData }); }} dataSource={CategoryList} />*/}
                    <MultiSelectComponent id="mtselement" placeholder="Select Subcategories" value={this.state.fSubcategoryId} fields={Fields} change={this.handleFilterSelectChange} dataSource={CategoryList} mode="CheckBox" >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                    {/*<Select2 className='form-control form-control-sm' multiple defaultValue={this.state.SelectedCategories} data={mapedData} onSelect={this.handleSelectChange} onUnselect={this.handleSelectChange} />*/}
                    {/*<Select2 className='form-control form-control-sm' multiple data={mapedData}  />*/}
                  </div>
                </div>

              </div>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Products</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbSku' name='tbSku' placeholder='SKU' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Sku} onChange={e => this.setState({ Sku: e.target.value })} /> <div id='tbSkuError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddProductBrandId' name='ddProductBrandId' placeholder='Select Brand' dataSource={this.state.BrandList} fields={{ text: 'title', value: 'id' }} floatLabelType='Always' value={this.state.ProductBrandId} change={e => this.setState({ ProductBrandId: e.itemData.id })} />
                <div id='tbProductBrandIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbGTIN' name='tbGTIN' placeholder='GTIN' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.GTIN} onChange={e => this.setState({ GTIN: e.target.value })} /> <div id='tbGTINError' className='error-message' />
              </div>

            
            </div>

            
            <div className='mb-3 '>
              <Label className="mb-0 text-dark">Select Subcategories</Label>
              <Select2 className='form-control form-control-sm' multiple defaultValue={this.state.SelectedCategories} data={mapedData} onSelect={this.handleSelectChange} onUnselect={this.handleSelectChange} />
            </div>

            <div className='mb-3'>
              <Label for='tbShortDescription'>Short Description</Label>
              <RichTextEditorComponent id='tbShortDescription' name='tbShortDescription' placeholder='Short Description' toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.ShortDescription} change={e => this.setState({ ShortDescription: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbShortDescriptionError' className='error-message' />
            </div>

            
            <em style={{ fontSize: "11px" }}>(Include measuring units)</em>
            <div className="row">
              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbWeight' name='tbWeight' placeholder='Weight' type='text' maxLength='20' floatLabelType='Always' showClearButton={true} value={this.state.Weight} onChange={e => this.setState({ Weight: e.target.value })} /> <div id='tbWeightError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbHeight' name='tbHeight' placeholder='Height' type='text' maxLength='20' floatLabelType='Always' showClearButton={true} value={this.state.Height} onChange={e => this.setState({ Height: e.target.value })} /> <div id='tbHeightError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbLength' name='tbLength' placeholder='Length' type='text' maxLength='20' floatLabelType='Always' showClearButton={true} value={this.state.Length} onChange={e => this.setState({ Length: e.target.value })} /> <div id='tbLengthError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbWidth' name='tbWidth' placeholder='Width' type='text' maxLength='20' floatLabelType='Always' showClearButton={true} value={this.state.Width} onChange={e => this.setState({ Width: e.target.value })} /> <div id='tbWidthError' className='error-message' />
              </div>

            </div>


            <div className="row">
              <div className='mb-3 col-md-3'>
                <NumericTextBoxComponent id='tbPrice' name='tbPrice' placeholder='Price' floatLabelType='Auto' showClearButton={true} value={this.state.Price} onChange={e => this.setState({ Price: e.target.value })} /> <div id='tbPriceError' className='error-message' />
              </div>
              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbStock' name='tbStock' placeholder='Stock' type='text' maxLength='20' floatLabelType='Auto' showClearButton={true} value={this.state.Stock} onChange={e => this.setState({ Stock: parseInt(e.target.value) })} /> <div id='tbStockError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbStockCount' name='tbStockCount' placeholder='Stock Count' type='text' maxLength='20' floatLabelType='Auto' showClearButton={true} value={this.state.StockCount} onChange={e => this.setState({ StockCount: parseInt(e.target.value) })} /> <div id='tbStockCountError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbLowStockAmount' name='tbLowStockAmount' placeholder='Low Stock Amount' type='text' maxLength='20' floatLabelType='Auto' showClearButton={true} value={this.state.LowStockAmount} onChange={e => this.setState({ LowStockAmount: parseInt(e.target.value) })} /> <div id='tbLowStockAmountError' className='error-message' />
              </div>

            </div>

            <h6>Sale</h6>
            <div className="row">
              <div className='mb-3 col-md-4'>
                <NumericTextBoxComponent id='tbSPrice' name='tbSPrice' placeholder='Sale Price' floatLabelType='Auto' showClearButton={true} value={this.state.SPrice} onChange={e => this.setState({ SPrice: e.target.value })} /> <div id='tbSPriceError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <DatePickerComponent id='tbDateSPriceStarts' name='tbDateSPriceStarts' placeholder='Date Sale Starts' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.DateSPriceStarts} onChange={e => this.setState({ DateSPriceStarts: e.target.value })} /> <div id='tbDateSPriceStartsError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <DatePickerComponent id='tbDateSPriceEnds' name='tbDateSPriceEnds' placeholder='Date Sale Ends' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.DateSPriceEnds} onChange={e => this.setState({ DateSPriceEnds: e.target.value })} /> <div id='tbDateSPriceEndsError' className='error-message' />
              </div>
            </div>

            <div className="row">
              <div className='mb-3 col-md-6 mt-4'>
                <CheckBoxComponent id='cbOngoing' name='cbOngoing' type='checkbox' label='Ongoing?' checked={this.state.Ongoing} onChange={e => this.setState({ Ongoing: e.target.checked })} /> &nbsp; <div id='cbOngoingError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6 mt-4'>
                <CheckBoxComponent id='cbOutOfStock' name='cbOutOfStock' type='checkbox' label='Out Of Stock' checked={this.state.OutOfStock} onChange={e => this.setState({ OutOfStock: e.target.checked })} /> &nbsp; <div id='cbOutOfStockError' className='error-message' />
              </div>
            </div>


          

            <div className='mb-3'>
              <Label for='tbAbout'>About</Label>
              <RichTextEditorComponent id='tbAbout' name='tbAbout' placeholder='About' toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.About} change={e => this.setState({ About: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbAboutError' className='error-message' />
            </div>

            <div className='mb-3'>
              <Label for='tbAdditionalInfo'>Additional Info</Label>
              <RichTextEditorComponent id='tbAdditionalInfo' name='tbAdditionalInfo' placeholder='Additional Info' toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.AdditionalInfo} change={e => this.setState({ AdditionalInfo: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbAdditionalInfoError' className='error-message' />
            </div>

            <div className='mb-3'>
              <TextBoxComponent id='tbTags' name='tbTags' placeholder='Tags' type='text' maxLength='500' floatLabelType='Auto' showClearButton={true} value={this.state.Tags} onChange={e => this.setState({ Tags: e.target.value })} /> <div id='Tags' className='error-message' />
            </div>

            <div className='mb-3'>
              <Label>Product Images</Label>
              <ImageThumbnails imageUrls={images} onUpdateImageUrls={this.handleUpdatedImageUrls} />
            </div>

           

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={(e) => this.saveItem(e)}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        {<Modal isOpen={this.state.previewModal} toggle={this.previewToggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.previewToggle} close={<button className="close" onClick={this.previewToggle}><i className="fas fa-times"></i></button>}>Product Preview</ModalHeader>
          <ModalBody>
            <PreviewCards
              product={this.state.PreviewProduct}
              currPrice={(this.state.SPrice && this.state.DateSPriceEnds && new Date(this.state.DateSPriceEnds) >= new Date()) || (this.state.SPrice && this.state.Ongoing) ? this.state.SPrice : this.state.Price}
              prevPrice={(this.state.SPrice && this.state.DateSPriceEnds && new Date(this.state.DateSPriceEnds) >= new Date()) || (this.state.SPrice && this.state.Ongoing) ? this.state.Price : this.state.SPrice}
              title={this.state.Title}
              description={this.state.ShortDescription} />
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.previewToggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>}

        <Modal isOpen={this.state.uploadModal} toggle={this.uploadToggle} className={this.props.className} scrollable size="md" backdrop="static">
          <ModalHeader toggle={this.uploadToggle} close={<button className="close" onClick={this.uploadToggle}><i className="fas fa-times"></i></button>}>Upload Products</ModalHeader>
          <ModalBody>
            <input placeholder='Browse excel documents ' type="file" accept='.xlsx, .xls' onChange={(e) => uploadExcel(e)} />
            <div id='uploadData' className='text-danger'></div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.uploadToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={e => this.uploadExcelData()}>Upload <i className='far fa-save-circle ms-2'></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/products', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.filter(item => item.status !== 2);
        this.setState({ editData: [...data, { SelectedCategories: data.SelectedCategories && JSON.parse(data?.SelectedCategories), }] });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/productbrands', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BrandList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/productcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CategoryList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/productsubcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SubCategoryList: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId, bulk) {

    this.setState({ loading: true, showError: false, showSuccess: false });
    //console.log(dataId)
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Slug: this.state.Slug, ProductBrandId: this.state.ProductBrandId, SelectedCategories: JSON.stringify(this.state.SelectedCategories), ProductRangeId: this.state.ProductRangeId, ProductTypeId: this.state.ProductTypeId, GTIN: this.state.GTIN, Title: this.state.Title, ShortDescription: this.state.ShortDescription, DisplayOrder: this.state.DisplayOrder, Weight: this.state.Weight, Height: this.state.Height, Length: this.state.Length, Width: this.state.Width, SPrice: this.state.SPrice, DateSPriceStarts: this.state.DateSPriceStarts, DateSPriceEnds: this.state.DateSPriceEnds, Upsells: this.state.Upsells, CrossSells: this.state.CrossSells, Stock: this.state.Stock, Quantity: this.state.Quantity, Price: this.state.Price, Images: Array.isArray(this.state.Images) ? this.state.Images.join(", ") : this.state.Images, Sku: this.state.Sku, Published: this.state.Published, Featured: this.state.Featured, About: this.state.About, AdditionalInfo: this.state.AdditionalInfo, Tags: this.state.Tags, RelatedProducts: this.state.RelatedProducts, MetaData: this.state.MetaData, ExternalURL: this.state.ExternalURL, DisplayType: this.state.DisplayType, OutOfStock: this.state.OutOfStock, Ongoing: this.state.Ongoing, StockCount: this.state.StockCount, LowStockAmount: this.state.LowStockAmount, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, }
    var body = bulk ? bulk : data;
    //console.log(data)
    try {
      const response = await fetch('api/products', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Products', content: 'The products was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        this.setState({ loading: false });
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Products', content: 'There was an error saving the products!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveBulkData(bulk) {

    this.setState({ loading: true, showError: false, showSuccess: false });
    //console.log(dataId)
    var bearer = 'Bearer ' + getAuthToken();
    // var data = { Id: this.state.Id, Slug: this.state.Slug, ProductBrandId: this.state.ProductBrandId, SelectedCategories: JSON.stringify(this.state.SelectedCategories), ProductRangeId: this.state.ProductRangeId, ProductTypeId: this.state.ProductTypeId, GTIN: this.state.GTIN, Title: this.state.Title, ShortDescription: this.state.ShortDescription, DisplayOrder: this.state.DisplayOrder, Weight: this.state.Weight, Height: this.state.Height, Length: this.state.Length, Width: this.state.Width, SPrice: this.state.SPrice, DateSPriceStarts: this.state.DateSPriceStarts, DateSPriceEnds: this.state.DateSPriceEnds, Upsells: this.state.Upsells, CrossSells: this.state.CrossSells, Stock: this.state.Stock, Quantity: this.state.Quantity, Price: this.state.Price, Images: Array.isArray(this.state.Images) ? this.state.Images.join(", ") : this.state.Images, Sku: this.state.Sku, Published: this.state.Published, Featured: this.state.Featured, About: this.state.About, AdditionalInfo: this.state.AdditionalInfo, Tags: this.state.Tags, RelatedProducts: this.state.RelatedProducts, MetaData: this.state.MetaData, ExternalURL: this.state.ExternalURL, DisplayType: this.state.DisplayType, OutOfStock: this.state.OutOfStock, StockCount: this.state.StockCount, LowStockAmount: this.state.LowStockAmount, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, }
    var body = bulk;
    //console.log(data)
    try {
      const response = await fetch('api/products/bulkupload', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Products', content: 'The products was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        this.setState({ loading: false });
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Products', content: 'There was an error saving the products!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/products/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Products', content: 'The products was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Products', content: 'There was an error deleting the products!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

