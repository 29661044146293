import React, { Component } from 'react';
import { Button, Label, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import "react-datepicker/dist/react-datepicker.css";
import SalesPerfomance from './Admin Dashboard/SalesPerfomance';
import ShoppingFunnel from './Admin Dashboard/ShoppingFunnel';
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import SocialMediaAnalytics from './Admin Dashboard/SocialMediaAnalytics';
import RevenueSources from './Admin Dashboard/RevenueSources';
import SalesGraphs from './Admin Dashboard/SalesGraph';
import { toHash } from 'ajv/dist/compile/util';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleAnalytics from './GoogleAnalytics';



export class AdminDashboard extends Component {

  static options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  static endDate = moment().add(1, "days").format("yyyy-MM-DD");
  static startDate = moment().add(-30, "days").format("yyyy-MM-DD");
  static contextType = Context;

  constructor(props) {
    super(props);


    this.state = {
      loading: true,
      reportStartDate: AdminDashboard.startDate, reportEndDate: AdminDashboard.endDate,
      SalePerformanceData: null,
      FunnelData: [],
      RevenueSaleData: [],
      RevenueSourceData: [],
      SocialMediaData: [],
      SearchData: [],
      fProductId: 0,
      ddProducts: [],
    };

    this.renderDashboard = this.renderDashboard.bind(this);
  }

  componentDidMount() {
    document.title = "Admin Dashboard :: TriggerCraft";
    this.context.getProducts();
      this.loadData();
      //this.googleLogin();
    }

    //googleLogin = useGoogleLogin({
    //    clientId: '986281077299-plkqehrf7otvbhaad3o5713mlaje9jdb.apps.googleusercontent.com',
    //    responseType: 'token',
    //    onSuccess: async (tokenResponse) => {
    //        const accessToken = tokenResponse?.access_token;
    //        if (accessToken) {
    //            this.fetchData(accessToken);
    //        }
    //    },
    //    onError: (error) => {
    //        console.error(error);
    //    }
    //});

    //componentDidUpdate(prevProps, prevState) {
        
    //    const { googleLogin } = this.context;
    //    if (googleLogin.tokenResponse !== prevProps.googleLogin.tokenResponse) {
    //        this.fetchData(googleLogin.tokenResponse.access_token);
    //    }
    //}

  ManageDateRange = (e) => {
    if (e.endDate)
      this.setState({ reportStartDate: moment(e.startDate).format("yyyy-MM-DD"), reportEndDate: moment(e.endDate).format("yyyy-MM-DD"), loading: true }, () => this.loadData());
    }

  renderDashboard = (PerformanceData, FunnelData, RevenueSaleData, RevenueSourceData, SocialMediaData, SearchData) => {
    return (
      <>
        <SalesPerfomance PerformanceData={PerformanceData} />
        <div className="row graphs justify-content-between mb-3">
          <div className="col-md-4">
            <ShoppingFunnel FunnelData={FunnelData} />
          </div>
          <div className="col-md-8">
            <SalesGraphs SalesData={RevenueSaleData} />
          </div>
        </div>
        <div className="card col-12">
          <div className="card-header bg-light text-center">
            <strong>Revenue sources</strong>
          </div>
          <div className="row flex-charts">
                    {/*<div className="col-lg-4 col-sm-12">
                        
                        <GoogleAnalytics startDate={this.state.reportStartDate} endDate={this.state.reportEndDate} />*/}
              {/*<SocialMediaAnalytics ProductId={this.state.fProductId} StartDate={this.state.reportStartDate} EndDate={this.state.reportEndDate} />
            </div>*/}
            <div className="col-md-12">
              <RevenueSources RevenueData={RevenueSourceData} />
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {

    const { products, handleNavigate } = this.context;
    const content = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDashboard(this.state.SalePerformanceData, this.state.FunnelData, this.state.RevenueSaleData, this.state.RevenueSourceData, this.state.SocialMediaData, this.state.SearchData);
    const primaryyAxis = { minimum: 0, maximum: 700, interval: 150, valueType: 'Double', labelFormat: 'R###' };
    const ProductDropdownList = [{ id: 0, title: "- All Products -" }, ...products]

    return (
      <>
        <div className="container">
          <Row className='mb-3'>
            <Col xs={6}>
              <h1 className='text-dark fw-light'>Admin Dashboard</h1>
            </Col>
            <Col md={6} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate("/admin/administration")}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Link to="/admin/order-dashboard" className="btn btn-sm text-light" style={{ background: "#74bc1f" }} >Order dashboard <i className='fas fa-arrow-circle-right'></i></Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className='card card-body shadow mb-4'>
                <b>Filter</b>
                <div className='row'>
                  <div className='col-md'>
                    <DropDownListComponent id='ddProductId' name='ddProductId' placeholder='Product' allowFiltering={true} filtering={(e) => console.log(e)} dataSource={ProductDropdownList} fields={{ text: 'title', value: 'id' }} floatLabelType='Always' value={this.state.fProductId} select={e => this.setState({ fProductId: e.itemData ? e.itemData.id : 0, loading: true }, () => this.loadData())} />
                  </div>
                  <div className='col-md'>
                    <Label className="mb-0"><small className='text-muted fw-semibold fs-7'> Date range</small></Label>
                    <br />
                    <DateRangePickerComponent startDate={this.state.reportStartDate} endDate={this.state.reportEndDate} select={this.ManageDateRange} format={"yyyy/MM/dd"} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {content}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  async loadData() {
    console.log("Been called from somewhere");
      var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(`api/admindashboard/${this.state.fProductId}/${this.state.reportStartDate}/${this.state.reportEndDate}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SalePerformanceData: data.item1, FunnelData: data.item2, RevenueSaleData: data.item3, RevenueSourceData: data.item4, SocialMediaData: data.item5, SearchData: data.item6, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href ="/login";
      }

    } catch (e) {
      console.error(e);
    }
    }

    //async fetchData(accessToken) {
    //    try {
    //        const propertyId = '409651800';
    //        const startDate = this.state.reportStartDate;
    //        const endDate = this.state.reportEndDate;

    //        const bearer = `Bearer ${accessToken}`;

    //        const metrics = [{ name: 'activeUsers' }];
    //        const dimensions = [{ name: 'country' }];

    //        const requestBody = {
    //            dateRanges: [{ startDate, endDate }],
    //            metrics,
    //            dimensions,
    //        };

    //        const response = await fetch(`https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runReport`, {
    //            method: 'POST',
    //            headers: {
    //                'Authorization': bearer,
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(requestBody)
    //        });

    //        if (response.ok) {
    //            const responseData = await response.json();
    //            console.log("responseData", responseData);
    //        } else {
    //            throw new Error(`HTTP error! status: ${response.status}`);
    //        }
    //    } catch (error) {
    //        console.error(error);
    //    }
    //};
}

