import React from 'react'
import { renderButton, checkSignedIn } from "../../../utils";
import { useState } from 'react';
import { useEffect } from 'react';
import { useGoogleApi, gapiObject } from 'react-gapi';
// import { OAuth2Client } from 'google-auth-library';

function SocialMediaAnalytics(props) {

    const gapi = useGoogleApi({
        access_type: "offline",
        scopes: ["https://www.googleapis.com/auth/analytics.readonly"]
    });
    const auth = gapi?.auth2.getAuthInstance();

    // if (!auth)


        // const oauth2Client = new OAuth2Client({
        //     clientId: "986281077299-ds8otopb14s9pbeq284e7fafe43mcmlf.apps.googleusercontent.com",
        //     clientSecret: "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",
        //     redirectUri: "https://localhost:44406/admin/"
        // });

        // const authorizeUrl = oauth2Client.generateAuthUrl({
        //     access_type : "offline",
        //     scope:"https://www.googleapis.com/auth/analytics.readonly"
        // });

        useEffect(() => {
            // window.gapi.load("auth2", init);
        });

    return (
        <></>
        // <div>
        //     {
        //         !isSignedIn ? (
        //             <div id="signin-button"></div>
        //         ) :
        //             (<div>Coming soon....</div>)
        //     }
        // </div>
    )
}

export default SocialMediaAnalytics
